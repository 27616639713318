<template>
<div class="Pagination">
  <el-pagination
      background
      hide-on-single-page
      @current-change="handleCurrentChange"
      v-model:currentPage="props.config.currentPage"
      :page-size="props.config.pageNum"
      layout="prev, pager, next, jumper"
      :total="props.config.total">
  </el-pagination>
</div>
</template>

<script>
import { useStore }  from "vuex"
import * as Vue from "vue";
import router from "@/router";
export default {
  name: "Pagination",
  components: {},
  props:['config'],
  setup(props, { attrs, slots, emit }) {
    const store = useStore()
    const state = Vue.reactive({
      currentPage: 1,
    })

    const handleCurrentChange=(val)=> {
      emit('handleCurrentPage', val)
    }

    return {
      state,
      props,
      handleCurrentChange
    }
  }
}
</script>

<style lang="scss" scoped>
@import "/src/comment/comment";
.Pagination{
  width: 100%;
  text-align: center;
  margin-top: 30px;
}


/deep/.el-pager li.active{
  background-color: #7F0404 !important;
}
/deep/.el-pagination.is-background .btn-next,
/deep/.el-pagination.is-background .btn-prev,
/deep/.el-pagination.is-background .el-pager li{
  background-color: white;
}
</style>