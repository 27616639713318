<template>
<div class="bgCenter">
  <div class="backBg">
    <div class="backgroundLeft">
      <div></div>
      <div></div>
    </div>
    <div class="backgroundRight">
      <div></div>
      <div></div>
    </div>
  </div>
  <div class="content">
    <slot></slot>
  </div>
</div>
</template>

<script>
import { useStore }  from "vuex"
import * as Vue from "vue";
import router from "@/router";
export default {
  name: "BgCenter",
  components: {},
  props:[''],
  setup(props, { attrs, slots, emit }) {
    const store = useStore()
    const state = Vue.reactive({})
    return {
      state,
      props
    }
  }
}
</script>

<style lang="scss" scoped>
.bgCenter{
  min-height: 300px;
  position: relative;
  padding: 60px 0;
  .backBg{
    width: 100%;
    height: 100%;
    z-index: -10;
    position: absolute;
    top: 0;
    .backgroundLeft {
      width: 326px;
      height: 100%;
      position: relative;
      overflow: hidden;
      float: left;
      div:nth-child(1){
        width: 100%;
        height: 120%;
        position: absolute;
        background-image: url("../../views/Home/img/redBg1.png");
        background-position: 100% 100%;
        background-repeat: repeat-y;
        background-size: contain;
        opacity: 0.05;
        z-index: -10;
        img{
          width: 100px;
          height: 100px;
        }
      }
      div:nth-child(2){
        width: 100%;
        height: 120%;
        position: absolute;
        background-image: url("../../views/Home/img/redBg1.png");
        background-position: 100% 100%;
        background-repeat: repeat-y;
        background-size: contain;
        transform: translate(-170px, -160px);
        opacity: 0.05;
        z-index: -10;
        img{
          width: 100px;
          height: 100px;
        }
      }
    }
    .backgroundRight {
      width: 326px;
      height: 100%;
      position: relative;
      overflow: hidden;
      float: right;
      div:nth-child(1){
        width: 100%;
        height: 120%;
        position: absolute;
        right: 0;
        background-image: url("../../views/Home/img/redBg1.png");
        background-position: 100% 100%;
        background-repeat: repeat-y;
        background-size: contain;
        opacity: 0.05;
        z-index: -10;
        img{
          width: 100px;
          height: 100px;
        }
      }
      div:nth-child(2){
        width: 100%;
        height: 120%;
        position: absolute;
        background-image: url("../../views/Home/img/redBg1.png");
        background-position: 100% 100%;
        background-repeat: repeat-y;
        background-size: contain;
        transform: translate(170px, -160px);
        opacity: 0.05;
        z-index: -10;
        img{
          width: 100px;
          height: 100px;
        }
      }
    }
  }
  .content{
    /deep/.centerBox{
      >div:nth-child(2),>div:nth-child(3){
        padding-top: 60px;
      }
    }
    /deep/>div:nth-child(2),>div:nth-child(3){
      padding-top: 60px;
    }
  }
}
</style>