<template>
  <div class="Box">
    <div class="limitBox CharacterBox">
      <div class="Left">
        <div class="Industry">
          <div class="mainTitle">
            <p>人物信息</p>
            <p>Industry Experts</p>
          </div>
          <div class="DisplayFlex">
            <div>
              <span
                v-for="(item, index) in state.Tabs"
                :key="index"
                :class="{ active: state.type === item.value }"
                @click="ChangeTypeIndex(item.value)"
                >{{ item.label }}</span
              >
            </div>
            <div class="SeeMore" @click="SeeMore">
              <p>
                <span>查看更多</span
                ><span class="iconfont iconarrowright"></span>
              </p>
            </div>
          </div>
        </div>
        <div class="CharacterList area_Character">
          <div
            v-for="(item, index) in state.CharacterList"
            :key="index"
            class="CharacterItem"
          >
            <div>
              <el-image
                :src="item?.img"
                fit="cover"
                class="cuImage"
                @click="PersonDetails(item)"
                style="width: 100px; height: 100px"
              >
                <template #error>
                  <div class="image-slot">
                    <span>加载中</span>
                  </div>
                </template>
              </el-image>
            </div>
            <div>
              <p @click="PersonDetails(item)">{{ item.title }}</p>
              <p>专业领域： {{ item.cate ? item.cate.name : "" }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="Right">
        <div class="Industry">
          <div class="mainTitle">
            <p>金融服务</p>
            <p>Financial Service</p>
          </div>
          <!-- <div class="SeeMore">
            <p>
              <span @click="$router.push('/PageDevelopment')">查看更多</span
              ><span class="iconfont iconarrowright"></span>
            </p>
          </div> -->
        </div>
        <div class="FeatureList">
          <el-image
            :src="require('../../Home/img/finance.jpg')"
            fit="cover"
            style="width: 100%; height: 200px"
          >
            <template #error>
              <div class="image-slot">
                <span>加载中</span>
              </div>
            </template>
          </el-image>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import * as Vue from "vue";
import router from "@/router";
import api from "@/comment/request/api";
export default {
  name: "Characters",
  components: {},
  setup(props, { attrs, slots, emit }) {
    const store = useStore();
    const state = Vue.reactive({
      Tabs: [
        {
          value: null,
          label: "全部",
        },
        {
          value: 0,
          label: "专家",
        },
        {
          value: 1,
          label: "艺术家",
        },
        {
          value: 2,
          label: "产业领军人物",
        },
      ],
      type: null,
      CharacterList: [],
    });
    const ChangeTypeIndex = (index) => {
      state.type = index;
      getDataList();
    };
    // 详情
    const PersonDetails = (item) => {
      router.push({
        name: "CharacterDetails",
        query: { id: item.character_id },
      });
    };
    // 列表
    const getDataList = () => {
      api.GetCharacterList({ limit: 3, type: state.type }).success((res) => {
        state.CharacterList = res.data.data.data;
      });
    };
    getDataList();
    const SeeMore = () => {
      router.push({
        name: "Character",
        query: { current: state.type },
      });
    };
    return {
      state,
      ChangeTypeIndex,
      PersonDetails,
      SeeMore,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "/src/comment/comment";

.CharacterBox {
  display: flex;
  justify-content: space-between;

  .Left {
    width: calc(50% - 15px);

    .CharacterList {
      margin-top: 15px;
      display: flex;
      // justify-content: space-between;
      text-align: center;

      .CharacterItem {
        width: calc(33% - 10px);
        margin-right: 40px;

        &:nth-child(3) {
          margin-right: 0;
        }

        > div:nth-child(1) {
          /deep/ .el-image {
            border-radius: 50%;
            box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
          }

          /deep/ .el-image__inner {
            border-radius: 50%;
          }
        }

        > div:nth-child(2) {
          p:nth-child(1) {
            font-size: 18px;
            font-family: PingFangSC-Semibold, PingFang SC;
            //font-weight: 600;
            color: #333333;
            padding: 10px 0;
            cursor: pointer;

            &:hover {
              color: #7f0404;
            }
          }

          p:nth-child(2) {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            padding: 10px 0 20px;
            line-height: 20px;
            border-bottom: 1px solid #7f0404;
            text-align: center;
          }
        }
      }
    }
  }

  .Right {
    width: calc(50% - 10px);

    .FeatureList {
      margin-top: 15px;
      height: 200px;
      background: #f4f4f4;
      border: 1px solid rgba(0, 0, 0, 0.15);

      .FeatureItem {
        display: flex;
        justify-content: space-between;

        > div:nth-child(2) {
          width: calc(100% - 225px);
          padding: 20px;

          p:nth-child(1) {
            font-size: 18px;
            font-family: PingFangSC-Semibold, PingFang SC;
            //font-weight: 600;
            color: #333;
          }

          p:nth-child(2) {
            font-size: 14px;
            line-height: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            text-indent: 2em;
            padding-top: 15px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
          }
        }
      }
    }
  }
}

.SeeMore {
  margin-left: 20px;
}

.Intro {
  /deep/ p:nth-child(2) {
    span {
      background-color: #f4f4f4 !important;
    }
  }
}
</style>