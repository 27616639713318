<template>
  <div class="address">
    <div>
      <span
        v-for="(item, index) in address.dataList"
        :key="index"
        :class="{ active: address.code === item.value }"
        @click="changeIndex(item.value)"
        >{{ item.label }}
      </span>
    </div>
  </div>
</template>

<script>
import * as Vue from "vue";
import api from "@/comment/request/api";
export default {
  name: "SearchAddress",
  props: [],
  setup(props, { attrs, slots, emit }) {
    const address = Vue.reactive({
      dataList: [{ value: null, label: "全部" }],
      code: null,
    });

    const changeIndex = (code) => {
      address.code = code;
      emit("getCurrentId", code);
    };

    api.GetRegionList().success((res) => {
      address.dataList = address.dataList.concat(res.data.data);
    });

    return {
      address,
      changeIndex,
    };
  },
};
</script>

<style lang="scss" scoped>
.address {
  margin-top: 15px;
  margin-bottom: 20px;
  width: 100%;
  height: 56px;
  line-height: 60px;
  background: #f4f4f4;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 20px 24px;
  box-sizing: border-box;
  div {
    display: flex;
    width: 100%;
    margin: 0 auto;
    cursor: pointer;
    span {
      height: 16px;
      line-height: 16px;
      flex-grow: 1;
      text-align: center;
      border-left: 1px solid #666666;
      font-weight: 400;
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      color: #666666;
    }
    span:last-child {
      border-right: 1px solid #666666;
    }
    .active {
      color: #7f0404;
      font-weight: 600;
    }
  }
}
</style>