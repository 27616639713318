<template>
  <div class="FlexRight">
    <div class="ErWeiMa">
      <div>
        <span class="iconfont iconerweima"></span>
        <span>二维码</span>
      </div>
      <div class="erWeiMaHover">
        <div>
          <div id="MobileEnd"></div>
        </div>
        <div>
          <p>扫描二维码</p>
          <p>打开移动端</p>
        </div>
      </div>
    </div>
    <div class="GoTop" @click="GoTop">
      <div>
        <span class="iconfont iconrocket"></span>
        <span>回到顶部</span>
      </div>
    </div>
  </div>
</template>

<script>
import * as Vue from "vue";
import QRCode from "qrcodejs2";
import $ from "jquery";
export default {
  name: "FlexRight",
  components: {},
  setup(props, { attrs, slots, emit }) {
    const state = Vue.reactive({})
    const GoTop=()=>{
      window.scrollTo(0, 0);
    }
    return {
      state,
      GoTop
    }
  },
  mounted() {
    var shareUrl = window.location.href;
    document.getElementById("MobileEnd").innerText=''
    new QRCode(document.getElementById("MobileEnd"), {
      text: shareUrl,
      width: 100,
      height: 100,
    });
    $(".ErWeiMa").mouseover(function () {
      $(".erWeiMaHover").show();
    }).mouseout(function () {
      $(".erWeiMaHover").hide();
    })
  }
}
</script>

<style lang="scss" scoped>
@import "/src/comment/comment";
.FlexRight{
  >div{
    cursor:pointer;
    padding: 10px;
    background-color: white;
    margin-top: 10px;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
    >div{
      display: flex;
      flex-direction:column;
      justify-content: center;
      span{
        color: #7D0303;
        text-align: center;
      }
      span:nth-child(1){
        font-size: 20px;
      }
      span:nth-child(2){
        font-size: 12px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
      }
    }
  }
  .GoTop{
    background-color: #7F0404;
    border-radius: 0 0 4px 4px;
    span{
      color: #FFFFFF;
    }
    span:nth-child(2){
      width: 35px;
      text-align: center;
      margin: 0 auto;
    }
  }
  //.ErWeiMa:hover{
  //  .erWeiMaHover{
  //    display: block;
  //  }
  //}
  .erWeiMaHover{
    display: none;
    //display: block;
    background: #FFFFFF;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 0;
    transform: translate(-150px,-20%);
    >div:nth-child(1){
      padding: 10px;
    }
    p{
      text-align: center;
      font-size: 12px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #7D0303;
    }
    //padding-bottom: 10px;
  }
  .erWeiMaHover:after{
    content:"";
    display:block;
    width:0px;
    height:0px;
    border:10px solid transparent;
    border-bottom:10px solid #ffffff;
    position:relative;
    left: 120px;
    bottom: 90px;
    transform:rotate(90deg);
  }
}
</style>