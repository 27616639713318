import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import {ElLoading} from 'element-plus'
import api from "@/comment/request/api";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      keepAlive:true
    },
  },
  {
    path: '/Root',
    name: 'Root',
    component: Home,
    meta:{
      keepAlive:true,
      path:'/'
    }
  },
  // 轮播详情
  {
    path:'/BannerDetails',
    name:'BannerDetails',
    component: () => import('../views/Banner/BannerDetails'),
    meta:{
      path:'/Banner',
      NoRoute:true
    }
  },
  {/*nav政策文件*/
    path: '/News',
    name: 'News',
    component: () => import('../views/News/News')
  },
  {/*新闻动态详情*/
    path: '/NewsDetails',
    name: 'NewsDetails',
    component: () => import('../views/News/NewsDetails'),
    meta:{
      path:'/News',
      NoRoute:true
    }
  },
  {/*广文数权*/
    path: '/Property',
    name: 'Property',
    component: () => import('../views/Property/Property')
  },
  // 广文数权详情
  {
    path:'/PropertyDetails',
    name:'PropertyDetails',
    component:() => import('../views/Property/PropertyDetails'),
    meta:{
      path:'/Property',
      NoRoute:true
    }
  },
  {/*文化活动 4*/
    path: '/Activity',
    name: 'Activity',
    meta: {
      // keepAlive:true
    },
    component: () => import('../views/Activity/Activity'),
  },
  // 文化活动详情
  {
    path:'/ActivityDetails',
    name:'ActivityDetails',
    component: () => import('../views/Activity/ActivityDetails'),
    meta:{
      path:'/Activity',
      NoRoute:true
    }
  },
  {/*新媒体电商 4*/
    path: '/Commerce',
    name: 'Commerce',
    meta: {
      // keepAlive:true
    },
    component: () => import('../views/Commerce/Commerce'),
  },
  // 新媒体电商详情
  {
    path:'/CommerceDetails',
    name:'CommerceDetails',
    component: () => import('../views/Commerce/CommerceDetails'),
    meta:{
      path:'/Commerce',
      NoRoute:true
    }
  },
  {/*地方资讯 4*/
    path: '/Local',
    name: 'Local',
    meta: {
      // keepAlive:true
    },
    component: () => import('../views/Local/Local'),
  },
  // 地方资讯详情
  {
    path:'/LocalDetails',
    name:'LocalDetails',
    component: () => import('../views/Local/LocalDetails'),
    meta:{
      path:'/Local',
      NoRoute:true
    }
  },
  {/*企业信息 5*/
    path: '/Enterprise',
    name: 'Enterprise',
    meta: {
      keepAlive:true
    },
    component: () => import('../views/Enterprise/Enterprise')
  },
  {/*企业信息详情 5*/
    path: '/EnterpriseDetails',
    name: 'EnterpriseDetails',
    component: () => import('../views/Enterprise/EnterpriseDetails'),
    meta:{
      path:'/Enterprise'
    }
  },
  // 项目信息
  {
    path:'/Project',
    name:'Project',
    component:() => import('../views/Project/Project'),
    meta: {
      // keepAlive:true
    },
  },
  // 项目详情
  {
    path:'/ProjectDetails',
    name:'ProjectDetails',
    component:() => import('../views/Project/ProjectDetails.vue'),
    meta: {
      path:'/Project'
    }
  },
  {/*人物信息 10*/
    path: '/Character',
    name: 'Character',
    component: () => import('../views/Character/Character')
  },
  {/*人物信息详情 10*/
    path: '/CharacterDetails',
    name: 'CharacterDetails',
    component: () => import('../views/Character/CharacterDetails'),
    meta:{
      path:'/Character'
    }
  },
  {/*非遗文化 12*/
    path: '/Intangible',
    name: 'Intangible',
    component: () => import('../views/Intangible/Intangible')
  },
  {/*东盟 13*/
    path: '/ASEAN',
    name: 'ASEAN',
    component: () => import('../views/ASEAN/ASEAN'),
    meta: {
      NoRoute:true
    }
  },
  // 其他内容
  {
    path:'/BottomCenter',
    name:'BottomCenter',
    component:() => import('../views/Home/Components/BottomCenter'),
    meta: {
      path:'/'
    }
  },
  // 建设中
  {
    path:'/PageDevelopment',
    name:'PageDevelopment',
    component:() => import('../views/PageDevelopment/PageDevelopment'),
    meta: {
      path:'/'
    }
  },
  //404
  {
    path:'/404',
    name:'404',
    component:() => import('../components/404')
  },
  {
    path:'/:pathMatch(.*)*',
    redirect:'/404'
  },
  // 登录
  {
    path:'/LoginPage',
    name:'LoginPage',
    meta:{
      path:'/',
      center:'login'
    },
    component: () => import('../views/Home/LoginPage'),
  },
  // 个人中心
  {
    path:'/PersonalCenter',
    name:'PersonalCenter',
    meta:{
      path:'/',
      center:'person'
    },
    component: () => import('../views/PersonalCenter/PersonalCenter'),
  },
  {
    path:'/TopicPage',
    name:'TopicPage',
    component: () => import('../views/TopicPage/TopicPage'),
    meta:{
      path:'/',
      NoRoute:true
    }
  },
]
// 路由
const router = createRouter({
  history: createWebHashHistory(),
  routes
})
// loading框设置局部刷新，且所有请求完成后关闭loading框
let loading;
function startLoading() {
  loading = ElLoading.service({
    lock: true,
    text: "Loading",
    background: 'rgba(255,255, 255, 1)',
    customClass:'loading_areaMain',
    target: document.querySelector('.loading-area')//设置加载动画区域
  });
}
function endLoading() {
  loading.close();
}
//声明一个对象用于存储请求个数
let needLoadingRequestCount = 0;
function showFullScreenLoading() {
  if (needLoadingRequestCount === 0) {
    // startLoading();
  }
  needLoadingRequestCount++;
};
function tryHideFullScreenLoading() {
  if (needLoadingRequestCount <= 0) return;
  needLoadingRequestCount--;
  if (needLoadingRequestCount === 0) {
    // endLoading();
  }
};

// 进入路由前方法勾子
router.beforeEach((to, from, next) => {
  /*
    to 目标路由
    from 源路由
    next 跳转到下一个路由
  */
  api.GetConfigs('plat_name,plat_keywords,plat_description').success((res)=>{
    let keyArr={
      title:[],
      keywords:[],
      description:[]
    };
    if(res.data.data){
      keyArr.title.push(res.data.data.plat_name)
      keyArr.keywords.push(res.data.data.plat_keywords)
      keyArr.description.push(res.data.data.plat_description)
      window.localStorage.setItem('keysSeo',JSON.stringify(keyArr))
    }
  })

  if(to.meta?.topic){
    window.startLoading();
  }

  /*加载loading*/
  showFullScreenLoading();
  next();
});
// 进入路由后方法勾子
router.afterEach((to, from) => {
  /*
    to 目标路由
    from 源路由
  */
  /*进入非首页页面到顶部*/
  if(to.name!=='Home'){
    window.scrollTo(0, 0);
  }

  /*取消loading*/
  setTimeout(() => {
    tryHideFullScreenLoading();
  },500)
});
export default router
