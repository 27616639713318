<template>
  <div class="Box">
    <div class="bg"></div>
    <div class="limitBox MoreInfoBox">
      <div class="mainTitle">
        <p>更多资讯</p>
        <p>MORE</p>
      </div>
      <div class="InfoList">
        <div
          v-for="(item, index) in state.InfoList"
          :key="index"
          class="InfoItem"
        >
          <div @click="GotoLianjie(item)">
            <el-image
              :src="item.image"
              fit="cover"
              style="width: 100%; height: 400px"
            >
              <template #error>
                <div class="image-slot">
                  <span>加载中</span>
                </div>
              </template>
            </el-image>
            <div class="InfoText">
              <p>{{ item.name }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Vue from "vue";
import router from "@/router";
export default {
  name: "MoreInfo",
  components: {},
  setup(props, { attrs, slots, emit }) {
    const state = Vue.reactive({
      InfoList: [
        {
          image: require("../img/image1.png"),
          name: "东盟文化",
          path: "ASEAN",
          type: 0,
        },
        {
          image: require("../img/image2.png"),
          name: "广西非遗",
          path: "Intangible",
          type: 0,
        },
        {
          image: require("../img/image4.png"),
          name: "广西文化",
          path: "http://122.112.199.74:8082/web/",
          type: 1,
        },
        {
          image: require("../img/image3.png"),
          name: "创意大赛",
          path: "http://www.gxctd.com/",
          type: 1,
        },
      ],
    });
    const GotoLianjie = (item) => {
      if (item.type) window.open(item.path, "_black");
      else {
        let routeUrl = router.resolve({
          name: item.path,
          query: {},
        });
        window.open(routeUrl.href, "_blank");
      }
    };
    return {
      state,
      GotoLianjie,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "/src/comment/comment";
.Box {
  padding: 20px 0;
  background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  position: relative;
  overflow: hidden;
  .bg {
    position: absolute;
    top: 0;
    left: 0;
    background: url("../img/bg.jpg");
    width: 100%;
    height: 770px;
  }
}
.mainTitle {
  z-index: 222;
}
.InfoList {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  .InfoItem {
    // width: 320px;
    width: calc(100% / 4 - 20px);
    height: 400px;
    cursor: pointer;
    position: relative;
    .InfoText {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      p {
        font-size: 43px;
        color: white;
        font-family: "PingFang SC";
        font-weight: 500;
        text-shadow: 2px 5px 10px rgba(0, 0, 0, 1);
      }
    }
  }
}
</style>