<template>
  <div class="Box BackgroundBox">
    <div class="limitBox contentBox">
      <div>
        <div class="Industry">
          <div class="mainTitle">
            <p>广文数权</p>
            <p>Culture & Digital copyright</p>
          </div>
          <div class="DisplayFlex">
            <div>
              <span
                v-for="(item, index) in state.Tabs"
                :key="index"
                :class="{ active: state.TypeIndex === item.value }"
                @click="ChangeTypeIndex(item.value)"
                >{{ item.label }}</span
              >
            </div>
            <div class="SeeMore">
              <p>
                <span @click="SeeMore">查看更多</span
                ><span class="iconfont iconarrowright"></span>
              </p>
            </div>
          </div>
        </div>
        <div class="IndustryContent loading_areaInd">
          <div class="IndustryLeft">
            <div>
              <el-image
                :src="state.NewsList[0]?.img"
                class="ImageInd"
                fit="cover"
                style="width: 100%; height: 250px"
                @click="GoToDetails(state.NewsList[0])"
              >
                <template #error>
                  <div class="image-slot">
                    <span>加载中</span>
                  </div>
                </template>
              </el-image>
            </div>
            <div class="content">
              <p
                class="oneline-overflow-elision"
                @click="GoToDetails(state.NewsList[0])"
              >
                {{ state.NewsList[0]?.title }}
              </p>
              <p class="moreline-overflow-elision-2">
                {{ state.NewsList[0]?.info }}
              </p>
            </div>
          </div>
          <div class="IndustryRight">
            <div
              v-for="(item, index) in state.NewsList"
              :key="index"
              class="newslist"
            >
              <p @click="GoToDetails(item)">
                <span>&sdot;</span>
                <span class="oneline-overflow-elision">{{ item.title }}</span>
                <span>{{ item.add_time.split(" ")[0] }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="Industry">
          <div class="mainTitle">
            <p>版权服务</p>
            <p>Copyright</p>
          </div>
        </div>
        <div class="copyRight">
          <div>
            <div>
              <p v-if="state.copyType === 0">版权申报情况</p>
              <p v-else>版权存证情况</p>
            </div>
            <div>
              <div class="tabTitle">
                <p v-if="state.copyType === 0">
                  <span>编号</span><span>状态</span>
                </p>
                <p v-else><span>存证备案号</span><span>主体名称</span></p>
              </div>
              <div v-if="state.copyType === 0" class="tabDataBox">
                <Vue3SeamlessScroll
                  v-if="state.copyRight.length > 0"
                  :copyNum="state.copyNum"
                  :hover="true"
                  :limitScrollNum="5"
                  :list="state.copyRight"
                  :step="0.5"
                  :wheel="false"
                  class="tabData"
                >
                  <p
                    v-for="(item, index) in state.copyRight"
                    :key="index"
                    @mouseover="CopyRight(item, index)"
                  >
                    <span>{{ item.number }}</span>
                    <span>{{ item.status_text }}</span>
                  </p>
                </Vue3SeamlessScroll>
                <div v-else class="tabData">
                  <p><span>暂无数据</span></p>
                </div>
                <div
                  v-if="state.copyRight.length > 0 && state.initial === true"
                  :class="state.DiaState === true ? 'clickItem' : ''"
                  class="rightDia"
                >
                  <p>作品名称:{{ state.copyRight[state.curCopy].title }}</p>
                  <p>作品类型:{{ state.copyRight[state.curCopy].type }}</p>
                  <p>版权编号:{{ state.copyRight[state.curCopy].number }}</p>
                  <p>状态:{{ state.copyRight[state.curCopy].status_text }}</p>
                </div>
              </div>
              <div v-else class="tabDataBox">
                <Vue3SeamlessScroll
                  v-if="state.copyRight.length > 0"
                  :copyNum="state.copyNum"
                  :hover="true"
                  :limitScrollNum="5"
                  :list="state.copyRight"
                  :step="0.3"
                  :wheel="false"
                  class="tabData"
                >
                  <p
                    v-for="(item, index) in state.copyRight"
                    :key="index"
                    @mouseover="CopyRight(item, index)"
                  >
                    <span>{{ item.preservationId }}</span>
                    <span>{{ item.entityName }}</span>
                  </p>
                </Vue3SeamlessScroll>
                <div v-else class="tabData">
                  <p><span>暂无数据</span></p>
                </div>
                <div
                  v-if="state.copyRight.length > 0 && state.initial === true"
                  :class="state.DiaState === true ? 'clickItem' : ''"
                  class="rightDia"
                >
                  <p>
                    主体证件号:{{
                      state.copyRight[state.curCopy].preservationId
                    }}
                  </p>
                  <p>
                    日期:{{
                      $dayjs(
                        Number(state.copyRight[state.curCopy].preservationTime)
                      ).format("YYYY-MM-DD HH:mm:ss")
                    }}
                  </p>
                  <p
                    style="word-break: break-all"
                    class="moreline-overflow-elision-2"
                  >
                    hash值:{{ state.copyRight[state.curCopy].docHash }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <p class="cursor" @click="PublishTo">
              <span></span><span>版权登记</span>
            </p>
            <p class="cursor" @click="RouterTo">
              <span></span><span>版权存证</span>
            </p>
            <p>版权申报、版权交易、版权查询等</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Vue from "vue";
import router from "@/router";
import api from "@/comment/request/api";
import { Vue3SeamlessScroll } from "vue3-seamless-scroll";
export default {
  name: "IndustryInformation",
  components: { Vue3SeamlessScroll },
  setup(props, { attrs, slots, emit }) {
    const state = Vue.reactive({
      Tabs: [
        {
          id: 1,
          name: "行业资讯",
        },
        {
          id: 2,
          name: "出版",
        },
        {
          id: 3,
          name: "影视",
        },
        {
          id: 4,
          name: "动漫",
        },
        {
          id: 5,
          name: "网络",
        },
        {
          id: 6,
          name: "创意",
        },
        {
          id: 7,
          name: "文旅",
        },
      ],
      TypeIndex: 0,
      imageUrl: require("../../../comment/image/home/image4.png"),
      NewsList: [
        {
          id: 1,
          title: "",
          add_time: "",
        },
      ],
      imageUrl2: require("../../../comment/image/home/image5.png"),
      copyRight: [],
      copyNum: 0,
      curCopy: 0,
      DiaState: false,
      initial: false /*初始状态*/,
      copyType: 1,
    });
    const ChangeTypeIndex = (index) => {
      state.TypeIndex = index;
      getDataList();
    };
    api.GetPropertyCate().success((res) => {
      state.Tabs = res.data.data;
      if (res.data.data.length) state.TypeIndex = res.data.data[0].value;
      getDataList();
    });
    const getDataList = () => {
      api
        .GetPropertyList({ limit: 8, cate_id: state.TypeIndex })
        .success((res) => {
          state.NewsList = res.data.data.data;
        });
    };
    const GoToDetails = (item) => {
      router.push({
        name: "PropertyDetails",
        query: {
          id: item.property_id,
        },
      });
    };
    // 更多
    const SeeMore = () => {
      router.push({
        name: "Property",
        query: {},
      });
    };
    const CopyRight = (item, index) => {
      state.curCopy = index;
      state.initial = true; /*初始状态改成true,可以有动画了*/
      state.DiaState = true;
    };
    const GetCopyRight = () => {
      api.GetCopyrightList().success((res) => {
        state.copyRight = res.data.data;
        if (state.copyRight.length > 4) {
          state.copyNum = 1;
        }
      });
    };
    GetCopyRight();
    const RouterTo = () => {
      window.open("https://gwbq.gxwhcy.com/", "_black");
    };
    const PublishTo = () => {
      window.open("https://gwbq.gxwhcy.com/", "_black");
    };

    return {
      state,
      ChangeTypeIndex,
      GoToDetails,
      SeeMore,
      CopyRight,
      RouterTo,
      PublishTo,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "/src/comment/comment";
.moreline-overflow-elision-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.ImageInd {
  cursor: pointer;
}

.DisplayFlex {
  .Industry {
    width: calc(100% - 100px);
  }

  .SeeMore {
    margin-left: 20px;
  }
}

.IndustryContent {
  //padding-bottom: 40px;
  overflow: hidden;

  .IndustryLeft {
    min-height: 360px;
    background-color: white;
    margin-top: 20px;
    width: 50%;
    float: left;
    font-size: 0; /*图片间隙*/

    .content {
      padding: 15px;
      background-color: white;

      p:nth-child(1) {
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        //font-weight: 600;
        color: #333333;
        cursor: pointer;

        &:hover {
          color: #7f0404;
        }
      }

      p:nth-child(2) {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        //text-indent: 1em;
        margin-top: 5px;
      }
    }
  }

  .IndustryRight {
    width: calc(50% - 30px);
    float: right;
    margin-top: 20px;

    .newslist {
      p {
        padding: 10px 0;
        overflow: hidden;
        border-bottom: 1px solid #d8d8d8;

        span {
          float: left;
          font-size: 16px;
          font-family: PingFangSC-Semibold, PingFang SC;
          //font-weight: 600;
          color: #333333;
        }

        span:nth-child(1) {
          margin-right: 7px;
        }

        span:nth-child(2) {
          width: calc(100% - 120px);
          display: inline-block;
          cursor: pointer;

          &:hover {
            color: #7f0404;
          }
        }

        span:nth-child(3) {
          float: right;
          font-weight: 400;
          color: #666666;
        }
      }
    }
  }
}

.copyRight {
  > div:nth-child(1) {
    margin-top: 12px;
    background: #f4f4f4;
    border-radius: 4px 4px 0px 0px;
    border: 1px solid rgba(0, 0, 0, 0.15);

    > div:nth-child(1) {
      margin: 12px 10px 10px 10px;
      padding-bottom: 15px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);

      p {
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #8b0000;
      }
    }

    > div:nth-child(2) {
      padding: 0 10px 10px 10px;

      .tabTitle {
        p {
          font-size: 16px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          line-height: 16px;
          padding: 5px 0 5px 0;
          display: flex;

          span:nth-child(1) {
            flex: 1;
          }

          span:nth-child(2) {
            width: 100px;
            display: inline-block;
          }
        }
      }

      .tabDataBox {
        height: 110px;
        position: relative;
        overflow: hidden;

        .rightDia {
          background-color: #f4f4f4;
          width: 80%;
          height: 107px;
          position: absolute;
          top: 0;
          right: 0;
          box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.3);
          border-radius: 4px;
          transform: translateX(110%);
          border: 1px solid rgba(0, 0, 0, 0.1);
          display: none;

          p {
            font-size: 14px;
            margin-left: 10px;
            margin-right: 10px;
            margin-top: 5px;
          }

          .closeDia {
            padding: 5px 15px;
            position: absolute;
            right: 0;
            bottom: 0;
            background-color: #7f0404;
            font-size: 12px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: white;
            border-radius: 0 0 4px 0;
          }
        }

        .clickItem {
          animation: transLeft 1s forwards;
          /* Safari 与 Chrome: */
          -webkit-animation: transLeft 1s forwards;
        }

        .clickItemClose {
          animation: transRight 1s forwards;
          /* Safari 与 Chrome: */
          -webkit-animation: transRight 1s forwards;
        }
      }

      .tabData {
        height: 110px;
        overflow: hidden;

        p {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 16px;
          margin-top: 11px;
          cursor: pointer;
          display: flex;

          span:nth-child(1) {
            flex: 1;
          }

          span:nth-child(2) {
            width: 100px;
            display: inline-block;
          }

          &:hover {
            color: #7f0404;
          }
        }
      }

      &:hover {
        .rightDia {
          display: block !important;
        }
      }
    }
  }

  > div:nth-child(2) {
    padding: 10px;
    background: #ffffff;
    border-radius: 0px 0px 4px 4px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-top: none;

    p:nth-child(1),
    p:nth-child(2) {
      height: 48px;
      line-height: 48px;
      background-color: #7f0404;
      border-radius: 4px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      span:nth-child(1) {
        background-image: url("../img/read.svg");
        background-size: 100% 100%;
        width: 21px;
        height: 21px;
        display: inline-block;
        margin-right: 5px;
      }

      span:nth-child(2) {
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
      }
    }

    p:nth-child(2) {
      span:nth-child(1) {
        background-image: url("../img/filedone.svg");
      }
    }

    p:nth-child(3) {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      text-align: center;
      color: #666666;
    }
  }
}

@keyframes transLeft {
  0% {
    transform: translateX(110%);
  }
  100% {
    transform: translateX(-20px);
  }
}

@keyframes transRight {
  0% {
    transform: translateX(-20px);
  }
  100% {
    transform: translateX(110%);
  }
}

.contentBox {
  display: flex;

  > div {
    &:nth-child(1) {
      flex: 1;
    }

    &:nth-child(2) {
      width: 440px;
      margin-left: 40px;
    }
  }

  .report {
    width: 440px;
    height: 360px;
    margin-top: 15px;
    font-size: 0;

    .toReport {
      width: 100%;
      height: 56px;
      background: #7f0404;
      line-height: 56px;
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      text-align: center;
      cursor: pointer;
    }
  }
}

.SpecialBox {
  margin-top: 40px;
  position: relative;

  > div:nth-child(1) {
    cursor: pointer;
  }

  > div:nth-child(2) {
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: #7f0404;
    padding: 10px 20px;
    cursor: pointer;
    display: none;

    p {
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
    }
  }
}
</style>