<template>
  <div class="Box">
    <div class="bottom_top">
      <div class="limitBox">
        <div class="left">
          <el-image
              :src="require('../Home/img/logoBottom.png')"
              fit="cover"
              style="width: 280px; height: 126px">
          </el-image>
        </div>
        <div class="right">
          <p>友情链接</p>
          <p>
          <span v-for="(item,index) in state.linkList" :key="index">
            <a :href=item.url target="_blank">{{ item.name }}</a>
          </span>
          </p>
        </div>
      </div>
    </div>
    <div class="bottom_bottom">
      <div>
        <p v-for="(item,index) in state.list" :key="index"><span @click="onClickCenter(item)">{{item.name}}</span></p>
      </div>
      <div>
        <div>
          <p>广西广文创意数字科技有限公司版权所有</p>
          <p>未经书面授权禁止复制或建立镜像</p>
        </div>
        <div>
          <p>举报电话: 0771-3927513</p>
          <p class="harmful"><a href="https://www.gxjubao.org.cn/" target="_blank">网上有害信息举报专区</a></p>
          <p>
            <a href="https://www.gxjubao.org.cn/" target="_blank">
              <span>互联网违法和不良信息在线举报</span>
            </a>
          </p>
        </div>
      </div>
      <p style="display: flex;justify-content: center;">
        <span>网站备案:<span @click="LinkIn" class="cursor">桂ICP备2021003891号-2</span></span>
        <span>
          <a
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=45010702001843"
              target="_blank"
              style="text-decoration: none;color: white;display: flex;align-items: center;"
          >
            <img src="http://www.beian.gov.cn/img/new/gongan.png" alt="" style="margin-right: 2px">
          桂公网安备 45010702001843号
        </a>
        </span>
      </p>
    </div>
  </div>
</template>

<script>
import * as Vue from "vue";
import router from "@/router";
import api from "@/comment/request/api";

export default {
  name: "Bottom",
  components: {},
  setup(props, {attrs, slots, emit}) {
    const state = Vue.reactive({
      logo: require('../../comment/image/home/logo2.svg'),
      linkList: {},
      list:[
        {
          name:'关于我们',
          key: 0
        },
        {
          name:'版权声明',
          key: 1
        },
        {
          name:'联系方式',
          key: 2
        },
        {
          name:'网站介绍',
          key: 3
        }
      ]
    })
    /*获取友情链接列表 */
    const GetDataList = () => {
      api.GetConfigs('outsite_link').success((res) => {
        if(res.data.data){
          state.linkList = JSON.parse(res.data.data.outsite_link)
        }
      })
    }
    GetDataList();
    // 备案
    const LinkIn=()=>{
      window.open('https://beian.miit.gov.cn/', '_blank');
    }
    // 跳转
    const onClickCenter = (item) => {
      router.push({
        name:'BottomCenter',
        query: { key: item.key }
      });
    }
    return {
      state,
      onClickCenter,
      LinkIn
    }
  }
}
</script>

<style lang="scss" scoped>
@import "/src/comment/comment";

.bottom_top {
  background-color: #1A2F48F2;
  //margin-top: 40px;
  padding-bottom: 40px;

  .limitBox {
    display: flex;
    justify-content: space-between;
    padding-top: 40px;
    color: white;

    .left {
      width: 400px;
      display: flex;
      //border-bottom: 5px solid white;
      padding-bottom: 10px;

      > div:nth-child(2) {
        margin-left: 10px;

        p:nth-child(1) {
          font-size: 40px;
          font-family: AlibabaPuHuiTi-Heavy, AlibabaPuHuiTi;
          font-weight: 800;
          color: #FFFFFF;
          margin-top: 10px;
        }

        p:nth-child(2) {
          font-size: 17px;
          font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
          font-weight: 400;
          color: #FFFFFF;
          margin-top: 10px;
        }
      }
    }

    .right {
      width: calc(100% - 450px);

      a:link {
        color: #FFFFFF;
      }

      a:visited {
        color: #FFFFFF;
      }

      p:nth-child(1) {
        font-size: 24px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        border-bottom: 1px solid #ffffff66;
        padding-bottom: 10px;

      }

      p:nth-child(2) {


        span {
          a {
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            text-decoration: none;
          }

          display: inline-block;
          margin-top: 10px;
          margin-right: 30px;
          opacity: 0.8;
        }
      }
    }
  }
}

.bottom_center {
  background: #1A2F48;
  padding: 40px 0;
  color: white;

  .limitBox {
    display: flex;
    justify-content: space-between;

    > div {
      width: calc(100%/4);
      >p {
        text-align: center;
        font-size: 24px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        span{
          &:hover{
            //color: #7F0404;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.bottom_bottom {
  //height: 56px;
  //line-height: 56px;
  padding: 10px 0;
  background-color: #0E1F34;
  text-align: center;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  //letter-spacing: 1px;
  >div:nth-child(1){
    display: flex;
    justify-content: center;
    p:not(p:last-child){
      &::after{
        content: '|';
      }
    }
  }
  >div:nth-child(1){
    span{
      &:hover{
        cursor: pointer;
      }
    }
  }
  >div:nth-child(2){
    >div{
      display: flex;
      justify-content: center;
      align-items: center;
      p:not(p:last-child){
        margin-right: 5px;
        &::after{
          content: '|';
          margin-left: 5px;
          color: white;
        }
      }
      p{
        margin-top: 4px;
      }
    }

    .harmful{
      color: #FA541C;
      a{
        color: #FA541C;
      }
    }
    a{
      color: #F1C03E;
    }
  }
  span {
    padding: 0 5px;
  }
  >p{
    margin-top: 4px;
  }
}
.zhushi {
  background: #1A2F48;
  padding: 40px 0;
  color: white;

  .limitBox {
    display: flex;
    justify-content: space-between;

    > div {
      width: calc(100%/4 - 20px);
    }

    > div:nth-child(1), > div:nth-child(2) {
      p:nth-child(1) {
        font-size: 24px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        padding-bottom: 10px;
      }

      p:nth-child(2) {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 24px;
        opacity: 0.8;
      }
    }

    .contactInfo{
      > div:nth-child(1), > div:nth-child(3) {
        p {
          font-size: 24px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #FFFFFF;
        }
      }

      > div:nth-child(2) {
        padding: 10px 0;

        p {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          padding: 2px 0;
          opacity: 0.8;
        }
      }
    }
  }
}
/deep/ .el-image {
  background-color: transparent !important;
}

.wordBreak {
  word-break: break-word;
}
.tipOff{
  display: flex;
  flex-direction: column;
  p{
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 24px;
    opacity: 0.8;
    a{
      color: #FFFFFF;
      text-decoration: none;
      &:hover{
        text-decoration: underline;
      }
    }
  }
  .tipfontB{
    font-size: 24px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    opacity:1;
    padding-bottom: 20px;
  }
  .harmful{
    font-size: 14px;
    font-weight: bold;
    color: red;
    a{
      color: red;
    }
  }
}
</style>