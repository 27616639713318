<template>
  <div class="header Box">
    <div class="HeaderBox limitBox">
      <div class="HeaderLeft">
        <div class="leftImage"></div>
        <div class="leftText" @click="LogoClick">
          <el-image
            :src="require('../Home/img/logo.png')"
            fit="cover"
            style="width: 179px; height: 80px"
          >
            <template #error>
              <div class="image-slot">
                <span>加载中</span>
              </div>
            </template>
          </el-image>
        </div>
      </div>
      <div v-if="state.IsLogin === false" class="HeaderRight">
        <div class="cursor" @click="OpenLoginDialog('login')">
          <span class="iconfont iconyonghu"></span><span>登录</span>
        </div>
        <div class="cursor" @click="OpenLoginDialog('register')">
          <span class="iconfont iconbianji"></span><span>注册</span>
        </div>
      </div>
      <div v-else class="HeaderRight">
        <div class="cursor" @click="GoToPersonalCenter">
          <span class="iconfont iconyonghu"></span
          ><span>[ {{ state.userInfo.name }} ]</span>
        </div>
        <div class="cursor">
          <span @click="cancel">注销</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import * as Vue from "vue";
import router from "@/router";
import { ElMessageBox, ElMessage } from "element-plus";
export default {
  name: "Header",
  components: { },
  setup(props, { attrs, slots, emit }) {
    const store = useStore();
    const state = Vue.reactive({
      activeName: "login",
      IsLogin: false,
      userInfo: {},
    });
    Vue.watch(
      () => store.state.IsLogin,
      () => {
        state.IsLogin = store.state.IsLogin;
        if (state.IsLogin === true) {
          let userInfo = JSON.parse(localStorage.getItem("gx_user"));
          state.userInfo = userInfo.user;
          store.commit("setUserInfo", userInfo.user);
        }
      },
      { deep: true }
    );
    Vue.watch(
      () => store.state.userInfo,
      () => {
        state.userInfo = store.state.userInfo;
      },
      { deep: true }
    );
    // 已登录
    if (localStorage.getItem("gx_user")) {
      state.IsLogin = true;
      let userInfo = JSON.parse(localStorage.getItem("gx_user"));
      state.userInfo = userInfo.user;
    }
    // 注销
    const cancel = () => {
      ElMessageBox.confirm("是否确认注销登录？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        state.IsLogin = false;
        store.commit("setIsLogin", false);
        localStorage.removeItem("gx_user");
        ElMessage({
          type: "success",
          message: "注销成功",
        });
        /*退出登录跳转到首页*/
        router.push({
          name: "Home",
        });
      });
    };
    // 登录
    const OpenLoginDialog = (activeName) => {
      state.activeName = activeName;
      router.push({ name: "LoginPage", params: { active: activeName } });
    };
    // 个人中心
    const GoToPersonalCenter = () => {
      router.push({
        name: "PersonalCenter",
      });
    };
    // 主页
    const LogoClick = () => {
      router.push({
        name: "Home",
      });
    };

    return {
      state,
      OpenLoginDialog,
      GoToPersonalCenter,
      LogoClick,
      cancel,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "/src/comment/comment";

/deep/ .el-image {
  background-color: transparent !important;
}

.header {
  height: 100px;
  background-color: #f9f9f9;

  .HeaderBox {
    display: flex;
    justify-content: space-between;

    .HeaderLeft {
      overflow: hidden;
      width: 300px;
      //padding: 15px 0;
      padding-top: 10px;

      .leftImage {
        float: left;
      }

      .leftText {
        float: left;
        //margin-left: 10px;
        cursor: pointer;

        span {
          font-family: AlibabaPuHuiTi-Heavy, AlibabaPuHuiTi;
          display: block;
          color: #7f0404;
        }

        > span:nth-child(1) {
          font-size: 24px;
          font-weight: 800;
        }

        > span:nth-child(2) {
          margin-top: 5px;
          font-size: 10px;
          font-weight: 400;
        }
      }
    }

    .HeaderRight {
      height: 100px;
      line-height: 100px;
      float: right;
      display: flex;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #7f0404;

      div {
        margin-left: 10px;

        span:nth-child(1) {
          width: 14px;
          height: 14px;
          color: #7f0404;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
        }

        span:nth-child(2) {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #7f0404;
          margin-left: 3px;
        }
      }
    }
  }
}
</style>