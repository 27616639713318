<template>
    <el-divider />
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
  .el-divider {
    background-color: #7f0404;
    margin: 40px 0;
  }
  .el-divider--horizontal {
    height: 5px;
  }
</style>