<template>
  <div class="Box BackgroundBox">
    <div class="limitBox">
      <div class="SpecialBox">
        <div v-if="state.specialInfo.home_banner2" @click="SpecialDetails">
          <el-image
            :src="state.specialInfo.home_banner2"
            fit="cover"
            style="width: 100%; height: 140px; display: block"
          >
            <template #error>
              <div class="image-slot">
                <span>加载中</span>
              </div>
            </template>
          </el-image>
        </div>
        <div v-else style="cursor: auto">
          <el-image
            :src="require('../img/special.png')"
            fit="cover"
            style="width: 100%; height: 140px; display: block"
          >
            <template #error>
              <div class="image-slot">
                <span>加载中</span>
              </div>
            </template>
          </el-image>
        </div>
      </div>

      <div class="Industry">
        <div class="mainTitle">
          <p>文化活动</p>
          <p>Cultural Activity</p>
        </div>
      </div>
      <div class="DisplayFlex">
        <div class="nav">
          <span
            v-for="(item, index) in state.cateOptions"
            :key="index"
            :class="{ active: state.cate_id === item.value }"
            @click="ChangeTypeIndex(item.value)"
            >{{ item.label }}</span
          >
        </div>
        <div class="SeeMore">
          <p>
            <span @click="SeeMore">查看更多</span
            ><span class="iconfont iconarrowright"></span>
          </p>
        </div>
      </div>
      <div class="CultureList loading_areaCul">
        <div
          v-for="(item, index) in state.cultureList"
          :key="index"
          class="CultureItem"
        >
          <div>
            <div v-if="item.img">
              <el-image
                :src="item.img"
                class="cuImage"
                fit="cover"
                style="width: 300px; height: 140px"
                @click="GoToDetails(item)"
              >
                <template #error>
                  <div class="image-slot">
                    <span>加载中</span>
                  </div>
                </template>
              </el-image>
            </div>
            <div v-else>
              <el-image
                :src="require('../img/logo.png')"
                class="cuImage"
                fit="contain"
                style="width: 300px; height: 140px; padding: 10px"
                @click="GoToDetails(item)"
              >
                <template #error>
                  <div class="image-slot">
                    <span>加载中</span>
                  </div>
                </template>
              </el-image>
            </div>
            <div class="cultureInfo">
              <div>
                <p
                  class="moreline-overflow-elision-2"
                  @click="GoToDetails(item)"
                >
                  {{ item.title }}
                </p>
                <p class="moreline-overflow-elision-2">{{ item.info }}</p>
              </div>
              <div>
                <p>
                  <span>发布时间：{{ item?.add_time.split(" ")[0] }}</span>
                </p>
                <p>
                  <span class="iconfont iconkejian"></span>
                  <span class="item">{{ item.views }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Vue from "vue";
import router from "@/router";
import api from "@/comment/request/api";

export default {
  name: "CultureList",
  components: {},
  setup(props, { attrs, slots, emit }) {
    const state = Vue.reactive({
      cateOptions: [{ value: null, label: "全部" }],
      cate_id: null,
      cultureList: [],
      specialInfo: {},
    });
    const ChangeTypeIndex = (cate_id) => {
      state.cate_id = cate_id;
      getDataList();
    };
    // 详情
    const GoToDetails = (item) => {
      router.push({
        name: "ActivityDetails",
        query: {
          id: item.activity_id,
        },
      });
    };
    // 类别
    api.GetActivityCate().success((res) => {
      state.cateOptions = state.cateOptions.concat(res.data.data);
    });
    // 列表
    const getDataList = () => {
      api.GetActivityList({ limit: 4, cate_id: state.cate_id }).success((res) => {
        state.cultureList = res.data.data.data;
      });
    };
    getDataList();
    // 更多
    const SeeMore = () => {
      router.push({
        name: "Activity",
        query: {
        },
      });
    };

    // 横幅
    api.GetConfigs("home_banner2,home_banner2_t,home_banner2_u").success((res) => {
      if (res.data.data) {
        state.specialInfo = res.data.data;
      }
    });
    const SpecialDetails = () => {
      if (state.specialInfo.home_banner2_t == 1) {
        window.open(state.specialInfo.home_banner2_u);
      } else if (state.specialInfo.home_banner2_t == 2) {
        let routeUrl = router.resolve({
          name: "BottomCenter",
          query: {
            key: 5,
          },
        });
        window.open(routeUrl.href, "_blank");
      }
    };
    return {
      state,
      ChangeTypeIndex,
      GoToDetails,
      SeeMore,
      SpecialDetails,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "/src/comment/comment";

.BackgroundBox {
  padding-bottom: 40px;
}

.CultureList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 40px;

  .CultureItem {
    width: calc(50% - 30px);
    display: flex;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    background-color: white;
    margin-top: 15px;

    > div {
      border-radius: 5px;
      display: flex;
      justify-content: flex-start;
      width: 100%;

      > div:nth-child(1) {
        //width: 300px;
        padding: 10px;

        /deep/ .el-image {
          display: block;
        }
      }

      .cultureInfo {
        width: calc(100% - 320px);
        margin-left: 10px;
        margin-right: 10px;

        > div:nth-child(1) {
          p:nth-child(1) {
            font-size: 16px;
            font-family: PingFangSC-Semibold, PingFang SC;
            //font-weight: 600;
            color: #333333;
            margin-top: 20px;
            min-height: 42px;
            cursor: pointer;

            &:hover {
              color: #7f0404;
            }
          }

          p:nth-child(2) {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            margin-top: 15px;
            height: 38px;
          }
        }

        > div:nth-child(2) {
          overflow: hidden;
          margin-top: 15px;

          span {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
          }

          iconfont {
            font-size: 16px;
          }

          p:nth-child(1) {
            float: left;
          }

          p:nth-child(2) {
            float: right;

            .item {
              padding-right: 10px;
              margin: 0 3px;
            }
          }
        }
      }
    }
  }
}

.Industry {
  border: none;

  > div {
    float: none !important;

    p {
      text-align: center;

      &:nth-child(2) {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}

.nav {
  width: calc(100% - 100px);
  text-align: center;
  height: 32px;
  line-height: 32px;

  span {
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    color: #666666;
    margin-left: 40px;
    cursor: pointer;
    border-left: 2px solid #666666;
    padding-left: 40px;
  }

  .active {
    font-weight: 600;
    color: #7f0404;
  }

  span:first-child {
    border-left: none;
  }
}

.SeeMore {
  width: 100px;
  height: 32px;
  line-height: 32px;
  //padding-top: 15px;
  //padding-bottom: 40px;
}

.SpecialBox {
  margin-top: 40px;
  position: relative;
  > div:nth-child(1) {
    cursor: pointer;
  }
  > div:nth-child(2) {
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: #7f0404;
    padding: 10px 20px;
    cursor: pointer;
    display: none;
    p {
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
    }
  }
}
</style>