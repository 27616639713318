//用来封装所有的请求nuxt connect ECONNREFUSED 127.0.0.1:80
import axios from './index'
import { useSuccess } from './config'
import { successCode } from './config'

// 添加success方法取代then，在success中不用判断res.code状态
useSuccess(successCode)

export default {
    // 配置
    GetConfigs(keys) {
        return axios.get(`/home/config`, { params: { keys: keys } });
    },
    // 轮播列表
    GetBannerList(params) {
        return axios.get(`/info/banner/index`, { params: params });
    },
    GetBannerDetails(id) {
        return axios.get(`/info/banner/detail`, {params: {id}});
    },
    // 政策列表
    GetNewsList(params) {
        return axios.get(`/info/news/index`, { params: params });
    },
    GetNewsDetails(id) {
        return axios.get(`/info/news/detail`, {params: {id}});
    },
    // 人物类别
    GetNewsCate() {
        return axios.get('/info/news/cate/option');
    },
    // 企业列表
    GetEnterpriseList(params) {
        return axios.get(`/industry/enterprise/index`, { params });
    },
    /*企业详细信息*/
    GetEnterpriseDetails(id) {
        return axios.get(`/industry/enterprise/detail`, {params: {id}});
    },
    // 企业类别
    GetEnterpriseCate() {
        return axios.get('/industry/enterprise/cate/option');
    },
    // 广西数权
    GetPropertyList(params) {
        return axios.get('/info/property/index', { params });
    },
    GetPropertyDetails(id) {
        return axios.get(`/info/property/detail`, {params: {id}});
    },
    // 数权类别
    GetPropertyCate() {
        return axios.get('/info/property/cate/option');
    },
    // 项目列表
    GetProjectList(params) {
        return axios.get('/industry/project/index', { params });
    },
    /*项目信息*/
    GetProjectDetails(id) {
        return axios.get(`/industry/project/detail`, {params: {id}});
    },
    // 项目信息
    getProjectDetails(id) {
        return axios.get(`/industry/project/${id}`)
    },
    // 项目类别
    GetProjectCate() {
        return axios.get('/industry/project/cate/option');
    },
    // 新媒体电商
    GetCommerceList(params) {
        return axios.get('/info/commerce/index', { params });
    },
    GetCommerceDetails(id) {
        return axios.get(`/info/commerce/detail`, {params: {id}});
    },
    // 电商类别
    GetCommerceCate() {
        return axios.get('/info/commerce/cate/option');
    },
    // 活动
    GetActivityList(params) {
        return axios.get('/info/activity/index', { params });
    },
    GetActivityDetails(id) {
        return axios.get(`/info/activity/detail`, {params: {id}});
    },
    // 活动类别
    GetActivityCate() {
        return axios.get('/info/activity/cate/option');
    },
    // 本地
    GetLocalList(params) {
        return axios.get('/info/local/index', { params });
    },
    GetLocalDetails(id) {
        return axios.get(`/info/local/detail`, {params: {id}});
    },
    // 人物
    GetCharacterList(params) {
        return axios.get('/culture/character/index', { params });
    },
    GetCharacterDetails(id) {
        return axios.get(`/culture/character/detail`, {params: {id}});
    },
    // 人物类别
    GetCharacterCate() {
        return axios.get('/culture/character/cate/option');
    },
    // 非遗
    GetIntangibleList(params) {
        return axios.get('/culture/intangible/index', { params });
    },
    // 非遗类别
    GetIntangibleCate() {
        return axios.get('/culture/intangible/cate/option');
    },
    // 地区
    GetRegionList() {
        return axios.get('/system/region/option');
    },
    /*登录*/
    Login(params) {
        return axios.post(`/login/login`, params)
    },
    /*注册*/
    Register(params) {
        return axios.post(`/login/register`, params)
    },
    /*修改密码*/
    UpdatePassword(params) {
        return axios.post(`/login/pwd`, params)
    },
    UpdateUserInfo(params) {
        return axios.post(`/user/user/edit`, params)
    },
    // 反馈
    GetFeedbackList(params) {
        return axios.get('/system/feedback/index', { params });
    },
    GetFeedbackDetails(id) {
        return axios.get(`/system/feedback/detail`, {params: {id}});
    },
    AddFeedback(params) {
        return axios.post(`/system/feedback/add`, params);
    },
    // 版权
    GetCopyrightList(params) {
        return axios.get('/industry/copyright/index', { params });
    },
};

