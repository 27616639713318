<template>
  <div class="Box BackgroundBox">
    <div class="page">
      <div v-for="(item,index) in state.BgImage" :key="index" :class="'bg'+(index+1)">
        <el-image
            style="width: 100%; height: 100%"
            :src="item.image"
            fit="cover">
          <template #error>
            <div class="image-slot">
              <span>加载中</span>
            </div>
          </template>
        </el-image>
      </div>
<!--      <div class="bg1"></div>-->
<!--      <div class="bg2"></div>-->
<!--      <div class="bg3"></div>-->
<!--      <div class="bg4"></div>-->
<!--      <div class="bg5"></div>-->
    </div>
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { useStore }  from "vuex"
import * as Vue from "vue";
import router from "@/router";
export default {
  components: {},
  setup(props, { attrs, slots, emit }) {
    const store = useStore()
    const state = Vue.reactive({
      BgImage:[
        {image:require("./img/1.png")},
        {image:require("./img/2.png")},
        {image:require("./img/3.png")},
        {image:require("./img/4.png")},
        {image:require("./img/5.png")},
      ]
    })

    return {
      state,
    }
  }
}
</script>

<style lang="scss" scoped>
@import "/src/comment/comment";
.Box{
  padding-bottom: 40px;
  //background: #F4F4F4;
  background-color: rgba(145, 145, 145, 0.1);
}
.page {
  position: relative;
  z-index: -10;
  .bg1 {
    width: 434px;
    height: 576px;
    //background: url('./img/1.png') no-repeat;
    //background-size: cover;
    position: absolute;
    left: 80px;
    top: 300px;
  }
  .bg2 {
    width: 726px;
    height: 386px;
    //background: url('./img/2.png') no-repeat;
    //background-size: cover;
    position: absolute;
    left: 50%;
    top: 430px;
  }
  .bg3 {
    width: 812px;
    height: 1089px;
    //background: url('./img/3.png') no-repeat;
    //background-size: cover;
    position: absolute;
    right: 0px;
    top: 500px;
  }
  .bg4 {
    width: 648px;
    height: 560px;
    //background: url('./img/4.png') no-repeat;
    //background-size: cover;
    position: absolute;
    left: 0;
    top: 970px;
  }
  .bg5 {
    width: 366px;
    height: 344px;
    //background: url('./img/5.png') no-repeat;
    //background-size: cover;
    position: absolute;
    left: 50%;
    top: 1055px;
  }
}
.content{
  z-index: 10;
}
/deep/.el-image{
  background-color: transparent !important;
}
</style>