<template>
  <Header
    v-if="
      routerName !== 'MobileDetails' &&
      routerName != undefined &&
      state.TopicMeta === false
    "
  ></Header>
  <NavBar
    v-if="
      routerName !== 'MobileDetails' &&
      routerName != undefined &&
      state.TopicMeta === false
    "
  ></NavBar>
  <!-- <router-view/> -->
  <div v-if="state.loading" class="all"></div>
  <div class="loading-area">
    <router-view v-slot="{ Component, route }">
      <keep-alive>
        <component
          :is="Component"
          :key="route.path"
          v-if="route.meta.keepAlive"
        />
      </keep-alive>
      <component :is="Component" v-if="!route.meta.keepAlive" />
    </router-view>
  </div>
  <Bottom
    v-if="
      routerName !== 'MobileDetails' &&
      routerName != undefined &&
      state.TopicMeta === false
    "
  ></Bottom>
</template>

<script>
import * as Vue from "vue";
import Header from "./views/Home/Header";
import NavBar from "./views/Home/NavBar";
import Bottom from "./views/Home/Bottom";
import { useRoute } from "vue-router";
import { ref, watchEffect } from "vue";
export default {
  name: "Home",
  components: {
    Header,
    NavBar,
    Bottom,
  },
  setup(props, { attrs, slots, emit }) {
    let routerName = ref();
    const state = Vue.reactive({
      TopicMeta: false,
      loading: false,
    });
    const route = useRoute();
    watchEffect(() => {
      routerName.value = route.name;
      if (route.meta.topic || route.meta.power) {
        state.TopicMeta = true;
      } else {
        state.TopicMeta = false;
      }
    });
    return {
      state,
      routerName,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../src/comment/comment";
.all {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  background: #ffffff;
}
/deep/ .loading_areaMain {
  .el-loading-spinner {
    position: fixed;

    .circular {
      width: 30px;
    }
  }
}

/deep/ .loading_areaClass {
  .circular {
    width: 30px;
  }
}

/deep/ .el-image {
  background-color: #f5f7fa;

  .image-slot {
    text-align: center;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    color: #c0c4cc;
  }
}
</style>
