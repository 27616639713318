<template>
  <div class="BackgroundBox Box">
    <div class="news limitBox">
      <div class="ContentBox">
        <div class="Lunbo">
          <el-carousel
            ref="LunboRef"
            :interval="4000"
            indicator-position="none"
            @change="LunboIndex"
          >
            <el-carousel-item v-for="(item, index) in state.Lunbo" :key="index">
              <el-image
                :src="item.img"
                class="ImageLunbo"
                fit="cover"
                style="width: 100%; height: 100%"
                @click="toDetails(item)"
              >
                <template #error>
                  <div class="image-slot">
                    <span>加载中</span>
                  </div>
                </template>
              </el-image>
            </el-carousel-item>
          </el-carousel>
          <div class="indicator">
            <span @click="toDetails(state.Lunbo[state.curIndex])">{{
              state.Lunbo[state.curIndex].title
            }}</span>
            <span>
              <span class="Inarrow" @click="ClickArrow(0)">&lt;</span>&nbsp;
              <span>0{{ state.curIndex + 1 }}</span
              >&nbsp;&nbsp;&frasl;&nbsp; <span>0{{ state.Lunbo?.length }}</span
              >&nbsp;
              <span class="Inarrow" @click="ClickArrow(1)">&gt;</span>
            </span>
          </div>
        </div>
        <div class="NewsListBox">
          <NewsComp :NewsCompConfig="state.NewsCompConfig"></NewsComp>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Vue from "vue";
import router from "@/router";
import api from "@/comment/request/api";
import NewsComp from "../Components/NewsComp";

export default {
  name: "News",
  components: { NewsComp },
  setup(props, { attrs, slots, emit }) {
    const state = Vue.reactive({
      Lunbo: [{ title: "" }],
      curIndex: 0,
      NewsCompConfig: {
        type: 1,
        title: "新闻动态",
        eTitle: "News",
        code: 1,
      },
    });
    /*轮播*/
    api.GetBannerList({ limit: 5 }).success((res) => {
      state.Lunbo = res.data.data.data;
    });
    // 滚动
    const LunboIndex = (index) => {
      state.curIndex = index;
    };
    // 详情
    const toDetails = (item) => {
      router.push({
        name: "BannerDetails",
        query: {
          id: item.banner_id,
        },
      });
    };
    return {
      state,
      LunboIndex,
      toDetails,
    };
  },
  methods: {
    // 切换
    ClickArrow(type) {
      if (type === 0) {
        this.$refs.LunboRef.prev();
      } else {
        this.$refs.LunboRef.next();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/src/comment/comment";

.BackgroundBox {
  padding-bottom: 40px;
}

.news {
  .Title {
    > div:nth-child(1) {
      text-align: center;
      font-size: 36px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #7f0404;
      margin-bottom: 15px;
      margin-top: 0;
      padding-top: 40px;
      cursor: pointer;
    }

    > div:nth-child(2) {
      width: 100%;
      p {
        text-align: left;
        display: block;
        width: 100%;
        text-indent: 2em;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .ContentBox {
    margin-top: 40px;
    overflow: hidden;

    .Lunbo {
      width: 50%;
      float: left;

      .ImageLunbo {
        cursor: pointer;
      }

      .indicator {
        width: 100%;
        height: 50px;
        line-height: 50px;
        overflow: hidden;
        text-align: center;
        background-color: white;

        > span {
          display: inline-block;
        }

        > span:nth-child(1) {
          width: 70%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: left;
          font-size: 16px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          cursor: pointer;

          &:hover {
            color: #7f0404;
          }
        }

        > span:nth-child(2) {
          padding: 0 10px;
          background-color: #7f0404;
          width: 20%;
          float: right;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;

          span:nth-child(2) {
            color: #f7b500;
          }
        }

        .Inarrow {
          cursor: pointer;
        }
      }
    }

    .NewsListBox {
      width: calc(50% - 30px);
      float: right;
    }
  }
}
</style>