<template>
  <div class="Box">
    <div class="background1"></div>
    <div class="background2"></div>
    <div class="FileList limitBox">
      <div>
        <NewsComp :NewsCompConfig="state.NewsCompConfig1" :pageN="5"></NewsComp>
      </div>
      <div>
        <NewsComp :NewsCompConfig="state.NewsCompConfig2" :pageN="5"></NewsComp>
      </div>
      <div>
        <NewsComp :NewsCompConfig="state.NewsCompConfig3" :pageN="5"></NewsComp>
      </div>
    </div>
  </div>
</template>

<script>
import * as Vue from "vue";
import NewsComp from "../Components/NewsComp";
export default {
  name: "NewsList",
  components: { NewsComp },
  setup(props, { attrs, slots, emit }) {
    const state = Vue.reactive({
      NewsCompConfig1: {
        type: 2 /*通知公告*/,
        title: "通知公告",
        eTitle: "Announcement",
        code: 2,
      },
      NewsCompConfig2: {
        type: 3 /*政策文件*/,
        title: "政策文件",
        eTitle: "Policy Document",
        code: 3,
      },
      NewsCompConfig3: {
        type: 4 /*政策解读*/,
        title: "政策解读",
        eTitle: "Interpretation",
        code: 4,
      },
    });
    return {
      state,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "/src/comment/comment";

.Box {
  position: relative;
  overflow: hidden;
  .background1 {
    width: 200px;
    height: 332px;
    position: absolute;
    right: -10px;
    top: 50%;
    transform: translateY(-50%);
    background: url("../img/redBg1.png");
    background-size: cover;
    opacity: 0.05;
    z-index: -10;
  }
  .background2 {
    width: 392px;
    height: 550px;
    position: absolute;
    left: -70px;
    top: -40px;
    background: url("../img/redBg1.png") center right no-repeat;
    background-size: cover;
    opacity: 0.05;
    z-index: -10;
  }
}
.FileList {
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  justify-content: space-between;
  > div {
    width: calc(33% - 30px);
  }
}
</style>