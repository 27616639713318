import { createStore } from 'vuex'

export default createStore({
  state: {
    reload:false,
    IsLogin:false,
    userInfo: {},
  },
  mutations: {
    setReload(state,value){
      state.reload=value;
    },
    setIsLogin(state,value){
      state.IsLogin=value;
    },
    setUserInfo(state,value){
      state.userInfo=value;
    }
  },
  actions: {
  },
  modules: {
  }
})
