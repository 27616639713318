<template>
  <div class="nav Box">
    <div class="NavBox limitBox" v-if="!$route.meta?.center">
      <router-link
        v-for="(item, index) in state.NavList"
        :key="index"
        :to="item.path"
        :class="paths == item.path ? 'NavClass active' : 'NavClass'"
      >
        <span @click="ExternalLinks(item)">{{ item.name }}</span>
      </router-link>
    </div>
    <div class="NavBox limitBox" v-else>
      <p class="BackText" @click="GoBack"><span> &lt; 返回主站</span></p>
    </div>
  </div>
</template>

<script>
import * as Vue from "vue";
import router from "@/router";
import { useRoute } from "vue-router";
import { watchEffect } from "vue";
export default {
  name: "NavBar",
  components: {},
  setup(props, { attrs, slots, emit }) {
    const state = Vue.reactive({
      NavList: [
        {
          id: 1,
          path: "/",
          name: "首页",
        },
        {
          id: 2,
          path: "/News",
          name: "政策文件",
        },
        {
          id: 3,
          path: "/Property",
          name: "广文数权",
        },
        {
          id: 4,
          path: "/Activity",
          name: "文化活动",
        },
        {
          id: 5,
          path: "/Enterprise",
          name: "企业信息",
        },
        {
          id: 6,
          path: "/Project",
          name: "项目信息",
        },
        {
          id: 8,
          path: "",
          name: "众包服务",
          outlink: "https://gwzb.gxwhcy.com/user/login",
        },
        {
          id: 15,
          path: "",
          name: "版权存证",
          outlink: "https://gwbq.gxwhcy.com/",
        },
        {
          id: 10,
          path: "/Character",
          name: "人物信息",
        },
        {
          id: 11,
          path: "PageDevelopment",
          name: "金融服务",
        },
        {
          id: 12,
          path: "/Intangible",
          name: "广西非遗",
        },
        {
          id: 13,
          path: "/ASEAN",
          name: "东盟文化",
        },
        {
          id: 14,
          path: "",
          name: "创意大赛",
          outlink: "http://www.gxctd.com/",
        },
      ],
    });
    const paths = Vue.ref("/");
    const route = useRoute();
    watchEffect(() => {
      paths.value = router.currentRoute.value.meta.path
        ? router.currentRoute.value.meta.path
        : route.path;
    });
    // 返回
    const GoBack = () => {
      router.push({ name: "Home" });
    };
    const ExternalLinks = (item) => {
      if (item.outlink) {
        window.open(item.outlink, "_black");
      }
      if (item.inlink) {
        window.open(item.inlink, "_self");
      }
    };
    return {
      state,
      paths,
      GoBack,
      ExternalLinks,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "/src/comment/comment";
.nav {
  height: 60px;
  margin: 0 auto;
  background-color: #7f0404;
  overflow: hidden;
  .NavBox {
    height: 60px;
    background-color: #7f0404;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;

    .NavClass {
      flex: 1 1 auto;
      color: white;
      text-decoration: none;
      height: 35px;
      line-height: 35px;
      vertical-align: middle;
      span {
        border-left: 3px solid #952323;
        width: 100%;
        text-align: center;
        vertical-align: middle;
        display: inline-block;
        font-size: 17px;
        font-family: PingFangSC-Regular, PingFang SC;
        color: #ffffff;
      }
    }
    .NavClass:last-child {
      span {
        border-right: 3px solid #952323;
      }
    }

    .active {
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
    }
  }
}
.BackText {
  font-size: 17px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  cursor: pointer;
}
</style>