export const baseURL = process.env.NODE_ENV === 'production' ? '/api' : 'http://www.gxwhcy.com/api'
export const successCode = 200

//为Promise加上success方法，当请求响应res.code === successCode的时候, 才调用回调
export const useSuccess = (successCode = 200) => {
    Promise.prototype.success = function (fn) {
        return this.then((res) => {
            if(res.status === successCode && fn) {
                return fn(res) ?? res
            } else {
                return res
            }
        })
    }
}

