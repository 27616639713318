<template>
  <div class="Box">
    <!--轮播和新闻动态-->
    <News />
    <!--通知公告、政策文件、政策解读-->
    <NewsList />
    <!-- 企业信息、重点项目-->
    <EnterpriseState />
    <!--广文数权、版权服务-->
    <IndustryInformation />
    <!--新媒体电商-->
    <NewMedia />
    <!--文化活动-->
    <CultureList />
    <!--地方资讯-->
    <LocalInfo />
    <!--人物和特色-->
    <Characters />
    <!--更多资讯-->
    <MoreInfo />
  </div>
  <div class="flexRight">
    <FlexRight></FlexRight>
  </div>
</template>

<script>
import News from "./Home/Main/News";
import NewsList from "./Home/Main/NewsList";
import IndustryInformation from "./Home/Main/IndustryInformation";
import EnterpriseState from "./Home/Main/EnterpriseState";
import NewMedia from "./Home/Main/NewMedia";
import CultureList from "./Home/Main/CultureList";
import Characters from "./Home/Main/Characters";
import MoreInfo from "./Home/Main/MoreInfo";
import FlexRight from "./Home/FlexRight";
import LocalInfo from "./Home/Main/LocalInfo.vue";
import * as Vue from "vue";
export default {
  name: "Home",
  components: {
    News,
    NewsList,
    IndustryInformation,
    EnterpriseState,
    NewMedia,
    CultureList,
    Characters,
    MoreInfo,
    FlexRight,
    LocalInfo,
  },
  setup(props, { attrs, slots, emit }) {
    const state = Vue.reactive({});
    return {
      state,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "/src/comment/comment";
.Box {
  position: relative;
}
.flexRight {
  position: fixed;
  right: 10px;
  top: 50%;
  z-index: 100;
}
</style>