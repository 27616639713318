<template>
  <div class="Box BackgroundBox">
    <div class="limitBox contentBox">
      <div>
        <div class="Industry">
          <div class="mainTitle">
            <p>新媒体电商</p>
            <p>E-commerce</p>
          </div>
          <div class="DisplayFlex">
            <div>
              <span
                v-for="(item, index) in state.Tabs"
                :key="index"
                :class="{ active: state.TypeIndex === item.value }"
                @click="ChangeTypeIndex(item.value)"
                >{{ item.label }}</span
              >
            </div>
            <div class="SeeMore">
              <p>
                <span @click="SeeMore">查看更多</span
                ><span class="iconfont iconarrowright"></span>
              </p>
            </div>
          </div>
        </div>
        <div class="IndustryContent loading_areaInd">
          <div class="IndustryLeft">
            <div>
              <el-image
                :src="state.NewsList[0]?.img"
                class="ImageInd"
                fit="cover"
                style="width: 100%; height: 250px"
                @click="GoToDetails(state.NewsList[0])"
              >
                <template #error>
                  <div class="image-slot">
                    <span>加载中</span>
                  </div>
                </template>
              </el-image>
            </div>
            <div class="content">
              <p
                class="oneline-overflow-elision"
                @click="GoToDetails(state.NewsList[0])"
              >
                {{ state.NewsList[0]?.title }}
              </p>
              <p class="moreline-overflow-elision-2">
                {{ state.NewsList[0]?.info }}
              </p>
            </div>
          </div>
          <div class="IndustryRight">
            <div
              v-for="(item, index) in state.NewsList"
              :key="index"
              class="newslist"
            >
              <p @click="GoToDetails(item)">
                <span>&sdot;</span>
                <span class="oneline-overflow-elision">{{ item.title }}</span>
                <span>{{ item.add_time.split(" ")[0] }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="Industry">
          <div class="mainTitle">
            <p>热销栏目</p>
            <p>Topseller</p>
          </div>
        </div>
        <div class="copyRight">
          <div v-if="state.VideoLink">
            <vue3VideoPlay
              v-bind="state.options"
              :src="state.VideoLink"
              width="100%"
              color="#409eff"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Vue from "vue";
import router from "@/router";
import api from "@/comment/request/api";
import { Vue3SeamlessScroll } from "vue3-seamless-scroll";
export default {
  name: "NewMedia",
  components: { Vue3SeamlessScroll },
  setup(props, { attrs, slots, emit }) {
    const state = Vue.reactive({
      Tabs: [],
      TypeIndex: 0,
      imageUrl: require("../../../comment/image/home/image4.png"),
      NewsList: [
        {
          id: 1,
          title: "",
          add_time: "",
        },
      ],
      imageUrl2: require("../../../comment/image/home/image5.png"),
      code: 1200100,
      VideoLink: "",
      curIndex: 0,
      options: {
        autoPlay: false,
      }
    });
    const ChangeTypeIndex = (index) => {
      state.TypeIndex = index;
      getDataList();
    };
    // 类别
    api.GetCommerceCate().success((res) => {
      state.Tabs = res.data.data;
      if (res.data.data.length) state.TypeIndex = res.data.data[0].value;
      getDataList();
    });
    // 列表
    const getDataList = () => {
      api
        .GetCommerceList({ limit: 8, cate_id: state.TypeIndex })
        .success((res) => {
          state.NewsList = res.data.data.data;
        });
    };
    // 详情
    const GoToDetails = (item) => {
      router.push({
        name: "CommerceDetails",
        query: {
          id: item.commerce_id,
        },
      });
    };
    // 更多
    const SeeMore = () => {
      router.push({
        name: "Commerce",
        query: {},
      });
    };
    // 视频
    api.GetConfigs("home_video").success((res) => {
      if (res.data.data) {
        state.VideoLink = res.data.data.home_video;
      }
    });

    return {
      state,
      ChangeTypeIndex,
      GoToDetails,
      SeeMore,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "/src/comment/comment";
.moreline-overflow-elision-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.ImageInd {
  cursor: pointer;
}

.DisplayFlex {
  .Industry {
    width: calc(100% - 100px);
  }

  .SeeMore {
    margin-left: 20px;
  }
}

.IndustryContent {
  //padding-bottom: 40px;
  overflow: hidden;

  .IndustryLeft {
    min-height: 360px;
    background-color: white;
    margin-top: 20px;
    width: 50%;
    float: left;
    font-size: 0; /*图片间隙*/

    .content {
      padding: 15px;
      background-color: white;

      p:nth-child(1) {
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        //font-weight: 600;
        color: #333333;
        cursor: pointer;

        &:hover {
          color: #7f0404;
        }
      }

      p:nth-child(2) {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        //text-indent: 1em;
        margin-top: 5px;
      }
    }
  }

  .IndustryRight {
    width: calc(50% - 30px);
    float: right;
    margin-top: 20px;

    .newslist {
      p {
        padding: 10px 0;
        overflow: hidden;
        border-bottom: 1px solid #d8d8d8;

        span {
          float: left;
          font-size: 16px;
          font-family: PingFangSC-Semibold, PingFang SC;
          //font-weight: 600;
          color: #333333;
        }

        span:nth-child(1) {
          margin-right: 7px;
        }

        span:nth-child(2) {
          width: calc(100% - 120px);
          display: inline-block;
          cursor: pointer;

          &:hover {
            color: #7f0404;
          }
        }

        span:nth-child(3) {
          float: right;
          font-weight: 400;
          color: #666666;
        }
      }
    }
  }
}

.copyRight {
  overflow: hidden;
  > div:nth-child(1) {
    background: #f4f4f4;
    border-radius: 4px 4px 0px 0px;
    border: 1px solid rgba(0, 0, 0, 0.15);

    > div:nth-child(1) {
      padding-bottom: 15px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);

      p {
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #8b0000;
      }
    }

    > div:nth-child(2) {
      .tabTitle {
        p {
          font-size: 16px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          line-height: 16px;
          padding: 5px 0 5px 0;
          display: flex;

          span:nth-child(1) {
            flex: 1;
          }

          span:nth-child(2) {
            width: 100px;
            display: inline-block;
          }
        }
      }

      .tabDataBox {
        height: 110px;
        position: relative;
        overflow: hidden;

        .rightDia {
          background-color: #f4f4f4;
          width: 80%;
          height: 107px;
          position: absolute;
          top: 0;
          right: 0;
          box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.3);
          border-radius: 4px;
          transform: translateX(110%);
          border: 1px solid rgba(0, 0, 0, 0.1);
          display: none;

          p {
            font-size: 14px;
            margin-left: 10px;
            margin-right: 10px;
            margin-top: 5px;
          }

          .closeDia {
            padding: 5px 15px;
            position: absolute;
            right: 0;
            bottom: 0;
            background-color: #7f0404;
            font-size: 12px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: white;
            border-radius: 0 0 4px 0;
          }
        }

        .clickItem {
          animation: transLeft 1s forwards;
          /* Safari 与 Chrome: */
          -webkit-animation: transLeft 1s forwards;
        }

        .clickItemClose {
          animation: transRight 1s forwards;
          /* Safari 与 Chrome: */
          -webkit-animation: transRight 1s forwards;
        }
      }

      .tabData {
        height: 110px;
        overflow: hidden;

        p {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 16px;
          margin-top: 11px;
          cursor: pointer;
          display: flex;

          span:nth-child(1) {
            flex: 1;
          }

          span:nth-child(2) {
            width: 100px;
            display: inline-block;
          }

          &:hover {
            color: #7f0404;
          }
        }
      }

      &:hover {
        .rightDia {
          display: block !important;
        }
      }
    }
  }

  > div:nth-child(2) {
    padding: 10px;
    background: #ffffff;
    border-radius: 0px 0px 4px 4px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-top: none;

    p:nth-child(1),
    p:nth-child(2) {
      height: 48px;
      line-height: 48px;
      background-color: #7f0404;
      border-radius: 4px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      span:nth-child(1) {
        background-image: url("../img/read.svg");
        background-size: 100% 100%;
        width: 21px;
        height: 21px;
        display: inline-block;
        margin-right: 5px;
      }

      span:nth-child(2) {
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
      }
    }

    p:nth-child(2) {
      span:nth-child(1) {
        background-image: url("../img/filedone.svg");
      }
    }

    p:nth-child(3) {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      text-align: center;
      color: #666666;
    }
  }
}

@keyframes transLeft {
  0% {
    transform: translateX(110%);
  }
  100% {
    transform: translateX(-20px);
  }
}

@keyframes transRight {
  0% {
    transform: translateX(-20px);
  }
  100% {
    transform: translateX(110%);
  }
}

.contentBox {
  display: flex;

  > div {
    &:nth-child(1) {
      flex: 1;
    }

    &:nth-child(2) {
      width: 440px;
      margin-left: 40px;
    }
  }
}

.SpecialBox {
  margin-top: 40px;
  position: relative;

  > div:nth-child(1) {
    cursor: pointer;
  }

  > div:nth-child(2) {
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: #7f0404;
    padding: 10px 20px;
    cursor: pointer;
    display: none;

    p {
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
    }
  }
}
</style>