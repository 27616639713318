import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import locale from 'element-plus/lib/locale/lang/zh-cn'
import Pagination from './components/Pagination/Pagination.vue'
import RedDivider from "./components/RedDivider/RedDivider.vue"
import BgPage from "./components/BgPage/BgPage.vue"
import BgCenter from "./components/BgPage/BgCenter";
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import './comment/comment.scss'
import api from '../src/comment/request/api'
import * as config from '../src/comment/request/config'
import $ from 'jquery'
import { ElLoading,ElMessage } from 'element-plus'
import vue3videoPlay from 'vue3-video-play' // 引入组件
import 'vue3-video-play/dist/style.css' // 引入css
import storage from '../src/comment/localstorage';
import vue3SeamlessScroll from "vue3-seamless-scroll";
import dayjs from "dayjs";

const app = createApp(App)

app.component("Pagination",Pagination)
app.component("RedDivider",RedDivider)
app.component('QuillEditor', QuillEditor)
app.component('BgPage', BgPage)
app.component('BgCenter',BgCenter)
app.config.globalProperties.$api = api
app.config.globalProperties.$dayjs = dayjs
app.config.globalProperties.$config = config;
app.config.globalProperties.$url = "/agent";  // 图片前缀
app.config.globalProperties.$ = $;
app.config.globalProperties.$message = ElementPlus;
window.message=ElMessage;
window.$=$;

window.loading = null;
window.startLoading = function (){
    window.loading=ElLoading.service({
        lock: true,
        text: "Loading",
        background: 'rgba(255,255, 255, 1)',
        customClass:'loading_areaMain',
        target: document.querySelector('.loading-area')//设置加载动画区域
    });
}
window.endLoading=function () {
    window.loading.close();
}

app.use(store).use(router).use(storage).use(vue3videoPlay).use(vue3SeamlessScroll).use(ElementPlus, { locale }).mount('#app')
