<template>
  <div class="NewsList">
    <div class="DisplayFlex">
      <div class="mainTitle">
        <p>{{ props.NewsCompConfig.title }}</p>
        <p>{{ props.NewsCompConfig.eTitle }}</p>
      </div>
      <div class="SeeMore">
        <p>
          <span
            @click="
              $router.push({
                path: '/News',
                query: { },
              })
            "
            >查看更多</span
          >
          <span class="iconfont iconarrowright"></span>
        </p>
      </div>
    </div>
    <div>
      <div
        v-for="(item, index) in state.NewsList"
        :key="index"
        class="newslist"
      >
        <p>
          <span>&sdot;</span>
          <span class="oneline-overflow-elision" @click="GoToDetails(item)">{{
            item.title
          }}</span>
          <span>{{ item?.add_time?.substring(0, 10) }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import * as Vue from "vue";
import router from "@/router";
import api from "@/comment/request/api";

export default {
  name: "NewsComp",
  components: {},
  props: ["NewsCompConfig", "topic", "pageN"],
  setup(props, { attrs, slots, emit }) {
    const state = Vue.reactive({
      NewsList: [],
    });
    // 列表
    const InitNews = () => {
      let num = props.pageN ? props.pageN : 7;
      api.GetNewsList({ limit: num, cate_id:  props.NewsCompConfig.type }).success((res) => {
        state.NewsList = res.data.data.data;
      });
    };
    InitNews();
    // 详情
    const GoToDetails = (item) => {
      router.push({
        name: "NewsDetails",
        query: {
          id: item.news_id,
        },
      });
    };
    return {
      state,
      props,
      GoToDetails,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "/src/comment/comment";

.NewsList {
  .DisplayFlex {
    border-bottom: 5px solid #d8d8d8;
    position: relative;
  }
  .NoBottom {
    border-bottom: none;
    display: flex;
    align-items: center;
    /deep/.SeeMore {
      bottom: auto;
    }
  }
  .mainTitle {
    width: calc(100% - 100px);
    margin-top: 12px;
    //margin-left: 30px;
    padding-bottom: 10px;
    line-height: 24px;
  }
  .topicTitle {
    display: flex;
    > p {
      margin-right: 10px;
      font-size: 24px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      line-height: 24px;
      margin-bottom: 10px;
      color: #d8d8d8;
      cursor: pointer;
    }
    .active {
      color: #7f0404;
    }
    > p:not(p:last-child):after {
      content: "|";
      color: #d8d8d8;
      font-weight: lighter;
      margin-left: 10px;
    }
  }
  .topicTitleTwo {
    width: 100%;
    background-color: #f1f1f1;
    display: flex;
    padding-left: 20px;
    p {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 22px;
      margin: 4px;
      padding: 9px 20px;
      cursor: pointer;
    }
    .active {
      font-weight: 600;
      color: #7f0404;
      background-color: #ffffff;
    }
  }
  .SeeMore {
    width: 100px;
    position: absolute;
    right: 0;
    bottom: 5px;
  }

  .newslist {
    //margin-left: 30px;
    p {
      padding: 8px 0;
      overflow: hidden;
      border-bottom: 1px solid #d8d8d8;

      span {
        float: left;
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        //font-weight: 600;
        color: #333333;
      }

      span:nth-child(1) {
        margin-right: 7px;
      }

      span:nth-child(2) {
        width: calc(100% - 120px);
        display: inline-block;
        cursor: pointer;

        &:hover {
          color: #7f0404;
          //font-weight: 600;
        }
      }

      span:nth-child(3) {
        float: right;
        font-weight: 400;
        color: #666666;
      }
    }
  }
}
.noPointer {
  span {
    cursor: auto;
  }
}
.pr10 {
  padding-right: 10px;
}
</style>