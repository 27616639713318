import axios from 'axios'
import router from '@/router'
import {baseURL} from './config'
import store from '@/store'
import {ElMessage} from 'element-plus'
import { ElLoading } from 'element-plus'
const instance = axios.create({
    baseURL,
    timeout: 30000
})
// loading框设置局部刷新，且所有请求完成后关闭loading框
let loading;
function startLoading() {
    loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: 'rgba(255, 255, 255, 0.7)',
        target: document.querySelector('.loading-area')//设置加载动画区域
    });
}
function endLoading() {
    loading.close();
}
//声明一个对象用于存储请求个数
let needLoadingRequestCount = 0;
function showFullScreenLoading() {
    if (needLoadingRequestCount === 0) {
        startLoading();
    }
    needLoadingRequestCount++;
};
function tryHideFullScreenLoading() {
    if (needLoadingRequestCount <= 0) return;
    needLoadingRequestCount--;
    if (needLoadingRequestCount === 0) {
        endLoading();
    }
};
//定义全局默认配置
//请求拦截器
instance.interceptors.request.use(config => {
    // 在发送请求之前做些什么
    //判断是否存在token，如果存在将每个页面header都添加token
    let userInfo = localStorage.getItem("gx_user");
    if (userInfo) {
        userInfo = JSON.parse(userInfo);
        config.headers.common['X-Token'] = userInfo.token;
    }
    //请求前发起loading
    // showFullScreenLoading();
    return config;
}, error => {
    // 请求错误
    setTimeout(() => {
        tryHideFullScreenLoading();
        ElMessage.error({
            message: '加载超时！',
            duration: 2000
        })
    },3000)
    return Promise.reject(error);
});

// 响应拦截器
let toast
let count = 0;
instance.interceptors.response.use(res => {
    // 响应成功关闭loading
    tryHideFullScreenLoading();
    if(res.data){
        if (res.data.code === 200) return res;
        // 登录
        else if(res.data.code === -1){
            toast?.clear?.()
            toast = ElMessage.error({
                message: res.data.msg,
                duration: 2000
            })
            window.localStorage.removeItem('gx_user')
            router.push({name:'LoginPage'})
            store.commit("setIsLogin",false);
        }
        // 错误
        else if(res.data.code === 400){
            toast?.clear?.()
            toast = ElMessage.error({
                message: res.data.msg,
                duration: 2000
            })
        } else return Promise.reject(res.data.msg);
    }
}, error => {
    // 响应失败关闭loading
    tryHideFullScreenLoading();
    toast?.clear?.()
    if(count===0){
        toast = ElMessage.error({
            message: `${error.res?.status ?? '0'}: ${error.res?.statusText ?? '网络超时'}`,
            duration: 2000
        })
        count++;
    }
    return Promise.reject(error);
});

export default instance